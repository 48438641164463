if (process.env.NODE_ENV === 'production') {
    window.onerror = function(message, source, lineno, colno, error) {
       // console.log('Interceptando um erro global:', message);
        if (typeof message === 'string' && message.includes('Minified React error')) {
         // console.log('Suprimindo erro minificado do React:', message);
          return true; // Suprime o erro
        }
        return false; // Deixa o erro continuar para o console
    };

    window.addEventListener('unhandledrejection', function(event) {
        // console.log('Interceptando uma rejeição não tratada:', event.reason);
    
        if (event.reason && event.reason.message && event.reason.message.includes('Minified React error')) {
         // console.log('Suprimindo erro minificado do React:', event.reason.message);
          event.preventDefault(); // Suprime o erro
        }
    });
    



    // const originalConsoleError = console.error;
  
    // console.error = function(...args) {
    //     console.log("verificando os argumentos", args);

    //   if (typeof args[0] === 'string') { //&& args[0].includes('Minified React error')
    //     // Não faça nada ou adicione um rastreamento do erro
    //     console.log("argumento localizado", args);
    //     return;
    //   }
    //   originalConsoleError.apply(console, args);
    // };
    // console.log("ambiente", process.env.NODE_ENV);
}

// normalize CSS across browsers
import "./src/css/normalize.css"
import "./src/styles/less/atlas.less"

